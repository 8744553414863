import React, { useState } from 'react';
import { Input, Dropdown, Menu, Button, Form, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';


function SearchHeader() {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
  
    const handleVisibleChange = (flag:any) => {
      setVisible(flag);
    };

    const searchFun = async (values:any) => {
      console.log('gelen',values)
    }
  
    const menu = (
      <Menu>
        <Menu.Item>
            <Form 
            form={form}
            layout="vertical"
            onFinish={searchFun}
            className='seach-form'
            >
                <Form.Item name='search'>
                    <Input  placeholder='Arama yap...' />
                </Form.Item>
                <Form.Item name='check' valuePropName="checked">
                  <Checkbox >Sadece Başlıklarda Ara</Checkbox>
                </Form.Item>
                <span><i>ya da</i></span>
                <Form.Item name='userName' style={{ marginTop:'5px'}}>
                  <Input  placeholder="Kullanıcı adıyla ara..." name='userSearch'/>
                </Form.Item>
                <Form.Item style={{textAlign:'right', marginTop:'15px'}}>
                  <Button className='header-search-btn' htmlType="submit">Ara</Button>
                </Form.Item>
                </Form>
        </Menu.Item>
      </Menu>
    );

    return (
        <Dropdown
        overlay={menu}
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        placement="topCenter"
      >
        <Button
          icon={<SearchOutlined />}
          onClick={() => setVisible(!visible)}
        />
      </Dropdown>
    );
}

export default SearchHeader;
