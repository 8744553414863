import {notification} from 'antd';
import {api} from '../services/apis';
import {LocalStorageService} from '../helpers/LocalStorage';

const getTopCategoriesApi = async (): Promise<Object> => {
  return await api.get(`categories`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const getSubCategoriesApi = async (categoryId:any): Promise<Object> => {
  return await api.get(`categories/${categoryId}/subcategories`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const setTopics = async (data: any): Promise<Object> => {
  return (
    await api.post(`topics`, data,  { headers: {
      'X-Visitor-Id': LocalStorageService.getVisitorId(),
      'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
      'X-Visitor-IP': LocalStorageService.getBrowserIp(),
      'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
      'X-Visitor-City': LocalStorageService.getBrowserCity()
     }} )
  );
};

const getCategoriesDetailsApi = async (): Promise<Object> => {
  return await api.get(`categories/details`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

const getSubcategorieDetailsApi = async (categoryId:any): Promise<Object> => {
  return await api.get(`categories/${categoryId}/subcategories/details`,
  { headers: {
    'X-Visitor-Id': LocalStorageService.getVisitorId(),
    'X-Visitor-Browser-Version': LocalStorageService.getBrowserVersion(),
    'X-Visitor-IP': LocalStorageService.getBrowserIp(),
    'X-Visitor-Country': LocalStorageService.getBrowserCountry() ,
    'X-Visitor-City': LocalStorageService.getBrowserCity()
   }}
  );
};

export {
  getTopCategoriesApi,
  getSubCategoriesApi,
  setTopics,
  getCategoriesDetailsApi,
  getSubcategorieDetailsApi
}