import React, { useEffect, useState} from "react";
import {Dropdown , Badge, Menu, Avatar, Button, Drawer, Grid} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.png";
import SearchHeader from "../components/SearchHeader";
import { UserOutlined, MailOutlined , NotificationFilled} from "@ant-design/icons";
import { LocalStorageService } from "../helpers/LocalStorage";
import { urls } from "../router/auth";

const Header = () => {
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState("1");
  let navigate = useNavigate();
  const location = useLocation();
  const { useBreakpoint } = Grid;
  const { SubMenu } = Menu;
  const screens = useBreakpoint();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const roler = LocalStorageService.getRole() === 'Administrator';
  const userNameT:any = LocalStorageService.getUserName();

  console.log('userNameT',userNameT)

  const handleClick = (e:any) => {
    setCurrent(e.key);
  };

  const onClickLogout = () => {
    navigate("/giris");
    LocalStorageService.removeAuthToken();
    LocalStorageService.removeBrowserCity();
    LocalStorageService.removeBrowserCountry();
    LocalStorageService.removeBrowserVersion();
    LocalStorageService.removeBrowserIp();
    LocalStorageService.setRole('Guest');
    LocalStorageService.removeUserName();
    // LocalStorageService.removeVisitorId();
  };

  const LoginClick = () => {
    navigate("/giris");
  }

  const menuItemStyle = {
    whiteSpace: 'normal',
    overflow: 'visible',
    textOverflow: 'clip',
  };

  const activeStyle = {
    backgroundColor: '#d9d9d9',
    color: '#000000',
  };

  const UserMenu = () => (
      <Menu style={{padding:'15px'}}>
      <div className="user-menu-box">
        <div className="user-menu-box-avatar">
            <Avatar size={100} icon={<UserOutlined />} />
        </div>
        <div>
          <span className="user-title">Nazlı Yazar Akyön</span>
          <div className="user-content">
					<dl>
						<dt>Mesajlar</dt>
						<dd>	0	</dd>
					</dl>
					<dl>
						<dt>Emoji Skoru</dt>
						<dd>0</dd>
					</dl>
						<dl>
							<dt>Ödül Puanları</dt>
							<dd>	0</dd>
						</dl>
				</div>
        </div>
      </div>
      <Menu.Divider />
      <Menu.Item key="0">
        <a href="">Profilim</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="">Ayarlar</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={onClickLogout}>
        Çıkış
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu
      mode="horizontal"
      style={{
        display: "flex",
        justifyContent: "center",
        border: "none",
      }}
      selectedKeys={[location.pathname]}
    >
      <Menu.Item key="/">
        <Link to="/" >Kadınca Gündem</Link>
      </Menu.Item>
      <Menu.Item key="/blog">
        <Link to="/blog">Blog</Link>
      </Menu.Item>
      <Menu.Item key="/diyet-kulubu">
        <Link to="/diyet-kulubu">Diyet Kulubü</Link>
      </Menu.Item>
      <Menu.Item key="/anneler-kulubu">
        <Link to="/anneler-kulubu">Anneler Kulubü</Link>
      </Menu.Item>
      <Menu.SubMenu key="SubMenu" title="Üyeler">
        <Menu.Item key="setting:1">Option 1</Menu.Item>
        <Menu.Item key="setting:2">Option 2</Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );

  const mobilMenu = (
    <Menu style={{ width: '100%', marginTop:'50px' }} mode="inline">
      <Menu.Item key="/">
        <Link to="/" style={{ fontWeight: '600' }} >
          Kadınca Gündem
        </Link>
      </Menu.Item>
      <Menu.Item key="m1">
        <Link to="/blog" style={{ fontWeight: '600' }}>
          Blog
        </Link>
      </Menu.Item>
      <Menu.Item key="m3">
        <Link to="/diyet-kulubu" style={{ fontWeight: '600' }}>
          Diyet Kulubü
        </Link>
      </Menu.Item>
      <Menu.Item key="m4">
        <Link to="/anneler-kulubu" style={{ fontWeight: '600' }}>
         Anneler Kulubü
        </Link>
      </Menu.Item>
      <SubMenu
        key="sub5"
        title={
          <span>
            <span>Üyeler</span>
          </span>
        }
      >
        <Menu.Item key="m1">
          <Link to={''}>
            Option 1
          </Link>
        </Menu.Item>
        <Menu.Item key="m2">
          <Link to={''}>
          Option 2
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  )

  const AppHeader = () => {
    const userName = userNameT && userNameT.split(" "); 
    console.log('WWuserName',userName)
    const userLetters = userName?.length && userName[0].charAt(0).toUpperCase();
    return (
        <Dropdown overlay={<UserMenu />} trigger={['click']}>
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Avatar icon={userLetters} />
            <span style={{ color: 'black', marginLeft: '8px' , fontSize:'12px'}}>{userName}</span>
          </div>
        </Dropdown>
    );
  };

  return (
   <header>
      <div className="header-top">
        <div className="header-center">
          {
            roler ? (
              <div className="use-account">
                <div className="account">
                  <AppHeader />
                </div>
                <div className="user-mail">
                <Badge count={1}>
                  <MailOutlined />
                </Badge>
                </div>
                <div className="user-notification">
                <Badge count={1}>
                  <NotificationFilled />
                </Badge>
                </div>
                <div className="user-search">
                <SearchHeader/>
                </div>
              </div>
            ):
            (
              <div className="use-account not-uesr">
                <div className="user-mail">
                  <Button onClick={LoginClick}>Giriş Yap</Button>
                </div>
                <div className="user-notification">
                  <Button>ÜCRETSİZ Üye Ol</Button>
                </div>
                <div className="user-search">
                <SearchHeader/>
                </div>
              </div>
            )
          }
          
        </div>
      </div>
      <div className="header-bottom">
        <div className="header-center-bottom">
          <div className="header-logo">
            <Link to='/'>
              <img src={Logo} width={250} style={{top:'-55px', position:'absolute'}}></img>
            </Link>
            </div>
          <div className="header-menu" style={{ display: 'flex'}}>
            <div>
              {screens.md ? ( // Masaüstü menüsü
                menu
              ) : (
                // Mobil menüsü
                <div className="mobile-menu">
                  <Button
                    icon={<MenuOutlined />}
                    type="primary"
                    onClick={() => setDrawerOpen(true)}
                    style={{ display: "block", margin: "0 auto", textAlign: "center" }}
                  />
                  <Drawer
                    title="Menu"
                    placement="left"
                    width={280}
                    onClose={() => setDrawerOpen(false)}
                    open={isDrawerOpen}
                    bodyStyle={{ padding: 0 }}
                  >
                    {mobilMenu}
                  </Drawer>
                </div>
              )}
            </div>
          </div>

        </div>
      </div>
   </header>
  );
}

export default Header;
