
export const validateGsmNumber = (value) => {
    const patt = new RegExp('(5)[0-9][0-9][0-9]([0-9]){6}');
    return patt.test(value) && value.length < 11
        ? Promise.resolve()
        : Promise.reject(new Error('Lütfen geçerli bir telefon numaranızı giriniz!'));
};
export const validateEmail = (value) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return value?.match(validRegex)
        ? Promise.resolve()
        : Promise.reject(new Error('Lütfen geçerli bir e-posta giriniz!'));
};
export const validatePassword = (value) => {
    const validRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!'+%&/()=?_*-.,])[A-Za-z\d@!'+%&/()=?_*-.,]{6,}$/;
    return value?.match(validRegex)
        ? Promise.resolve()
        : Promise.reject(new Error('En az altı karakter, en az bir büyük harf, bir küçük harf, bir rakam ve bir özel karakter içermelidir.'));
};
