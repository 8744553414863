import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopicUserCard from "../../components/TopicUserCard";
import { Button,  Popover, List, Modal, Pagination, Badge } from "antd";
import type { PaginationProps } from 'antd';
import { MessageOutlined , LikeOutlined,DislikeOutlined, HeartFilled, UserOutlined, FieldTimeOutlined} from "@ant-design/icons";
import Editor from '../../components/Editor';
import BlogBreadcrumb from "../../components/BlogBreadcrumb";


const Deneme = () => {
 
 

  return (
    <>
  link sayfası
   
    </>
  );
}

export default Deneme;
