import { notification } from 'antd';
import {urls} from '../router/auth';
import {LocalStorageService} from '../helpers/LocalStorage';

class Interceptor {
    constructor(axiosInstance) {
        this.axiosInstance = axiosInstance;
        this.requestInterceptor = null;
        this.responseInterceptor = null;

        this.setRequestInterceptor(axiosInstance);
        this.setResponseInterceptor(axiosInstance);
    }

    setRequestInterceptor() {
        this.requestInterceptor = this.axiosInstance.interceptors.request.use(
            (config) => {
                const authToken = LocalStorageService.getAuthToken() ?? LocalStorageService.getTokenVisitor();
                if (config) {
                    if (authToken) {
                        // config.headers['token'] = authToken;
                        config.headers.Authorization = `Bearer ${authToken}`;
                    }
                    config.headers.common.withCredentials = false;
                    config.headers.common.Accept = 'application/json';
                }

                return config;
            },
            (error) => Promise.reject(error)
        );
    }

    setResponseInterceptor() {
        this.responseInterceptor = this.axiosInstance.interceptors.response.use(
            (response) => {
                if (
                    response.data &&
                    response.data.message &&
                    !response.data.message.includes('ile Başarılı Bir Şekilde Oluşturuldu')
                ) {
                    notification.info({ message: response.data.message });
                }
                // if (response.headers && response.headers && response.headers['x-token']) {
                //     LocalStorageService.setAuthToken(response.headers['x-token']);
                // }
                return response;
            },

            ({ response: error }) => {
                if (error && (error.status === 500 || error.data.status === 500)) {
                    notification.error({message: 'Beklenmedik bir hata oluştu!'});
                } 
                if (error && (error.status === 498 || error.data.status === 498)) {
                    LocalStorageService.removeAuthToken();
                    LocalStorageService.removeAuthName();
                    LocalStorageService.removeActiveRow();
                    LocalStorageService.removeProvince();
                    LocalStorageService.removeUserRole();
                    if (window.location.pathname !== urls.login) {
                        window.location.href = urls.login;
                    } 
                } 
                return Promise.reject(error);
            }
        );
    }
}

export default Interceptor;
