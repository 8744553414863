import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Editor({ setHtmlContent, htmlContent }:any) {
    console.log('edit', htmlContent)

    const modules = {
        toolbar: [
            [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video'],
            ['clean']                                         
        ],
    };

    const formats = [
        'header', 'font',
        'list', 'bullet',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'align',
        'color', 'background',
        'link', 'image', 'video'
    ];

    return (
        <>
            <ReactQuill theme="snow" value={htmlContent} onChange={setHtmlContent} modules={modules} formats={formats} />
        </>
        
    );
}

export default Editor;
