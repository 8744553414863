import React from 'react';
import { Avatar, Card, Badge, Space, Typography } from 'antd';
import { UserOutlined, MessageOutlined, LikeOutlined, CalendarOutlined } from '@ant-design/icons';

const { Text } = Typography;

const TopicUserCard = ({userData}:any) => {
    const userStatus = userData.status;
    let location = -20 ;
    const dstatus = userData[0].statu;
    console.log('status',userData[0])
  return (
    <Card style={{ width: 180, textAlign: 'center', height:'100%' }}>
      {/* Avatar ve Kullanıcı Adı */}
          <Space direction="vertical" className='topic-user-avatar-space'>
              <Avatar className={`topic-user-avatar ${userData[0].statu ? 'online' : 'offline'}`} size={80} icon={<UserOutlined />} style={{ backgroundColor: '#C4C4A3' }} />
              <Text strong style={{ fontSize: '16px' }}>{userData[0].userName}</Text>
              <Text>Üye /
                {
                    userData[0].statu ?  <span style={{color:'#52c41a'}}> Aktif</span> : <span style={{color:'#ff0000'}}> Pasif</span>
                }
                 </Text>
          </Space>
          {/* Üye Bilgileri */}
          <Space className='user-information' direction="vertical" size="small">
              <Space>
                  <CalendarOutlined />
                  <Text className='t-u-span'>{userData[0].date}</Text>
              </Space>
              <Space>
                  <MessageOutlined />
                  <Text className='t-u-span'>{userData[0].mesaj}</Text>
              </Space>
              <Space>
                  <LikeOutlined />
                  <Text className='t-u-span'>{userData[0].like}</Text>
              </Space>
          </Space>
            {
                userData[0].badgeSaveUser ? (
                    <Badge.Ribbon text="Kayıtlı Üye" color="gold" style={{ top: location }} />
                ): ''
            }
            {
                userData[0].badgeMomClub ? (
                    <Badge.Ribbon text="Annneler Kulubü" color="pink" style={{ top: location + 30 }} />
                ): ''
            }
            {
                userData[0].badgeDietClub ? (
                    <Badge.Ribbon text="Diyet Kulubü" color="red" style={{ top: location + 60 }} />
                ): ''
            }
    </Card>
  );
};

export default TopicUserCard;
