import React from 'react';
import { Menu } from 'antd';
import {  Link } from "react-router-dom";
import { useLocation } from 'react-router';
import { HomeOutlined, MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';

// const { SubMenu } = Menu;

function Submenu() {
    const location = useLocation();
    const { pathname } = location;
console.log('pathname',pathname)
    return (
        <>
        {
            pathname === '/' ? (
                <Menu mode="horizontal" defaultSelectedKeys={['1']} className='submenu-style'>
                    <Menu.Item key="1">
                        <Link to=''>
                            Yeni Eklenen Mesajlar
                        </Link>  
                    </Menu.Item>
                    <Menu.Item key="2">
                        <Link to=''>
                            Yeni Kullanıcı Mesajları
                        </Link> 
                    </Menu.Item>
                    <Menu.Item key="3">
                        <Link to=''>
                            Yeni Eklenen Konular
                        </Link> 
                    </Menu.Item>
                    <Menu.Item key="4">
                        <Link to=''>
                            Trend Konular
                        </Link> 
                    </Menu.Item>
                {/* <SubMenu key="app" icon={<AppstoreOutlined />} title="App">
                    <Menu.Item key="option1">Option 1</Menu.Item>
                    <Menu.Item key="option2">Option 2</Menu.Item>
                    <SubMenu key="subApp" title="Submenu">
                        <Menu.Item key="option3">Option 3</Menu.Item>
                        <Menu.Item key="option4">Option 4</Menu.Item>
                    </SubMenu>
                </SubMenu> */}
               
                
            </Menu>
            ): 
            pathname === '/blog' ? 'blog meu' : ''
        }
            
        </>
    );
}

export default Submenu;
