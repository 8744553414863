import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopicUserCard from "../../components/TopicUserCard";
import { Button,  Popover, List, Modal, Pagination, Badge } from "antd";
import type { PaginationProps } from 'antd';
import { MessageOutlined , LikeOutlined,DislikeOutlined, HeartFilled, UserOutlined, FieldTimeOutlined} from "@ant-design/icons";
import Editor from '../../components/Editor';
import BlogBreadcrumb from "../../components/BlogBreadcrumb";


const TopicList = () => {
  const location = useLocation();
  const url = location.pathname.split('/');
  const categoryId = url[1];
  const [subcategorieDetails, setSubcategorieDetails] = useState<any>({});
  const [subcategorie, setSubcategorie] = useState<any>([]);
  const [timeAgoMap, setTimeAgoMap] = useState<Record<string, string>>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 2;

  const messages = [
    {
      id: 1,
      date: 'Bugün 12:30',
      text: [
        "Allah yardımcınız olsun inşallah, benim ikizlerim de 32 aylık, geçen hafta ikisine de bez bıraktırmaya çalıştım kızımla hallettik ama kaka sorunu yüzünden geri taktık. Oğlum bezine yapmak istiyordu, geçen denize gittik orada çok huzursuzlandı bezine dahi yapmadı.",
        "Metabolizma çalıştıracak şeyler yedirdiniz mi? Mesela meyvesuyuna zeytinyağı damlatmak işe yarayabilir, bana buradan bir arkadaş söylemişti tuvalet eğitiminde oğlu kaka yapmıyormuş ama zeytinyağlı salata suyu içince yapmış"
      ],
      answer: {
        user: "@nazliy",
        text: "Metabolizma çalıştıracak şeyler yedirdiniz mi? Mesela meyvesuyuna zeytinyağı damlatmak işe yarayabilir, bana buradan bir arkadaş söylemişti tuvalet eğitiminde oğlu kaka yapmıyormuş ama zeytinyağlı salata suyu içince yapmış"
      },
      likes: [{ id: 1, name: 'User1' }, { id: 2, name: 'User2' }, { id: 3, name: 'User3' }],
      user:[
        {
          userName:'nazliy',
          date:'13 Mart 2024',
          mesaj:'99',
          like:'13',
          badgeSaveUser:true,
          badgeMomClub:true,
          badgeDietClub:true,
          statu:true,
        }
      ]
    },
    {
      id: 2,
      date: 'Dün 12:30',
      text: [
        "Allah yardımcınız olsun inşallah, benim ikizlerim de 32 aylık, geçen hafta ikisine de bez bıraktırmaya çalıştım kızımla hallettik ama kaka sorunu yüzünden geri taktık. Oğlum bezine yapmak istiyordu, geçen denize gittik orada çok huzursuzlandı bezine dahi yapmadı.",
        "Metabolizma çalıştıracak şeyler yedirdiniz mi? Mesela meyvesuyuna zeytinyağı damlatmak işe yarayabilir, bana buradan bir arkadaş söylemişti tuvalet eğitiminde oğlu kaka yapmıyormuş ama zeytinyağlı salata suyu içince yapmış"
      ],
      answer: '',
      likes: [{ id: 1, name: 'User1' }, { id: 2, name: 'User2' }, { id: 3, name: 'User3' }],
      user:[
        {
          userName:'esra',
          date:'1 Nisan 2000',
          mesaj:'200',
          like:'123',
          badgeSaveUser:true,
          badgeMomClub:true,
          badgeDietClub:false,
          statu:false,
        }
      ]
    },
    {
      id: 3,
      date: '12.04.2024',
      text: [
        "Allah yardımcınız olsun inşallah, benim ikizlerim de 32 aylık, geçen hafta ikisine de bez bıraktırmaya çalıştım kızımla hallettik ama kaka sorunu yüzünden geri taktık. Oğlum bezine yapmak istiyordu, geçen denize gittik orada çok huzursuzlandı bezine dahi yapmadı.",
        "Metabolizma çalıştıracak şeyler yedirdiniz mi? Mesela meyvesuyuna zeytinyağı damlatmak işe yarayabilir, bana buradan bir arkadaş söylemişti tuvalet eğitiminde oğlu kaka yapmıyormuş ama zeytinyağlı salata suyu içince yapmış"
      ],
      answer: {
        user: "@nazliy",
        text: "Metabolizma çalıştıracak şeyler yedirdiniz mi? Mesela meyvesuyuna zeytinyağı damlatmak işe yarayabilir, bana buradan bir arkadaş söylemişti tuvalet eğitiminde oğlu kaka yapmıyormuş ama zeytinyağlı salata suyu içince yapmış"
      },
      likes: [{ id: 1, name: 'User1' }, { id: 2, name: 'User2' }, { id: 3, name: 'User3' }],
      user:[
        {
          userName:'yasemin',
          date:'13 Mart 2024',
          mesaj:'33',
          like:'44',
          badgeSaveUser:true,
          badgeMomClub:false,
          badgeDietClub:false,
          statu:true,
        }
      ]
    },
   
  ];

  const paginatedMessages = messages.slice((currentPage - 1) * pageSize, currentPage * pageSize);


  const handlePageChange = (page:any) => {
    setCurrentPage(page);
  };

 
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const likes = [
    { id: 1, name: "@nazliy" },
    { id: 2, name: "@esraaa" },
    { id: 3, name: "@nazan" },
  ];

  return (
    <>
    <BlogBreadcrumb/>
    <div className="topic-page-title">
      <h2>Bez bırakmadan sonra kabızlık 32 aylık erkek</h2>
      <span><UserOutlined /> nazliy / <FieldTimeOutlined /> 12.10.2024</span>
    </div>
      <Pagination 
        current={currentPage} 
        pageSize={pageSize} 
        total={4} 
        onChange={handlePageChange} 
        style={{ textAlign: 'center', marginTop: '20px auto 0', width:'99%' }} 
        showSizeChanger
        showQuickJumper
      />
      {paginatedMessages.map((message:any) => (
         <div className='blog-card' key={message.id}>
         <div className='cardx'>
           <article className='article'>
             <div className='right-txtt topic-list-div'>
                 <div className="topic-user-profil">
                   <TopicUserCard userData={message.user} />
                 </div>
                 <div className="topic-msj">
                   <div>
                     <div className="topic-msj-date" style={{position:'relative'}}>
                      {message.date}
                      <Badge.Ribbon text="Yeni" color="gold" style={{ top:'-25px' , right:'-17px'}} />
                      </div>
                     <div className="topic-msj-txt"> 
                        {message.text.map((paragraph:any, index:any) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                     </div>
                     <div className="topic-msj-answer">
                       <span>{message.answer.user}</span>
                       <p>
                       {message.answer.text}
                       </p>
                     </div>
                   </div>
 
                   <div>
                     <div className="topic-msj-btn">
                       <div className="btn-right">
                         <Button icon={<DislikeOutlined />}>Şikayet Et</Button>
                       </div>
                       <div className="btn-right">
                         <Button icon={<LikeOutlined />} >Beğen</Button>
                         <Button icon={<MessageOutlined />} onClick={showModal}>Yanıtla</Button>
                       </div>
                     </div>
                     <div className="topic-like">
                       <Popover content={<List
                           className="topic-like-list"
                           dataSource={likes}
                           renderItem={(item) => (
                             <List.Item key={item.id} className="lile-span">
                               <span>{item.name}</span>
                             </List.Item>
                           )}
                         />} 
                         trigger="hover">
                         <Button type="text" icon={<HeartFilled />} /> <span style={{marginBottom:'2px'}}>3</span>
                       </Popover>
                     </div>
                   </div>
                  
                 </div>
             </div>
           </article>
         </div>
       </div>
      ))}

      {/* <div className='blog-card'>
        <div className='cardx'>
          <article className='article'>
            <div className='right-txtt topic-list-div'>
                <div className="topic-user-profil">
                  <TopicUserCard status={false}/>
                </div>
                <div className="topic-msj">
                  <div>
                    <div className="topic-msj-date">Bugün 12:30 </div>
                    <div className="topic-msj-txt"> 
                      <p>
                      Allah yardımcınız olsun inşallah, benim ikizlerim de 32 aylık, geçen hafta ikisine de bez bıraktırmaya çalıştım kızımla hallettik ama kaka sorunu yüzünden geri taktık. Oğlum bezine yapmak istiyordu, geçen denize gittik orada çok huzursuzlandı bezine dahi yapmadı.
                      </p>
                      <p>
                    Metabolizma çalıştıracak şeyler yedirdiniz mi? Mesela meyvesuyuna zeytinyağı damlatmak işe yarayabilir, bana buradan bir arkadaş söylemişti tuvalet eğitiminde oğlu kaka yapmıyormuş ama zeytinyağlı salata suyu içince yapmış
                      </p>
                    </div>
                  </div>
                 
                 <div>
                  <div className="topic-msj-btn">
                      <div className="btn-right">
                        <Button icon={<DislikeOutlined />}>Şikayet Et</Button>
                      </div>
                      <div className="btn-right">
                        <Button icon={<LikeOutlined />} >Beğen</Button>
                        <Button icon={<MessageOutlined />} onClick={showModal}>Yanıtla</Button>
                      </div>
                    </div>
                    <div className="topic-like">
                      <Popover content={<List
                          className="topic-like-list"
                          dataSource={likes}
                          renderItem={(item) => (
                            <List.Item key={item.id} className="lile-span">
                              <span>{item.name}</span>
                            </List.Item>
                          )}
                        />} 
                        trigger="hover">
                        <Button type="text" icon={<HeartFilled />} /> <span style={{marginBottom:'2px'}}>3</span>
                      </Popover>
                    </div>
                 </div>
                 
                </div>
            </div>
          </article>
        </div>
      </div>

    */}

      <Modal title="Yorum yaz" className="reply-modal"
        open={isModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel}
        okText="Gönder"
        cancelText="İptal"
        width={700}
      >
       <Editor/>
      </Modal>
    </>
  );
}

export default TopicList;
