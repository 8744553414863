import React from 'react';
import { useLocation } from 'react-router';
import { Breadcrumb } from 'antd';
import { breadcrumbItems } from '../router/auth';
import { Link } from 'react-router-dom';

function BlogBreadcrumb() {
    const location = useLocation();
    const { pathname } = location;

    console.log('breadcrumbs',breadcrumbItems)
    console.log('pathname',pathname)

    return (
        <>
            <Breadcrumb style={{ margin: '40px 0 15px 0' }}>
                {breadcrumbItems.map((item, index) => (
                    <Breadcrumb.Item key={index}>
                        <Link to={item.url}>{item.name}</Link>
                    </Breadcrumb.Item>
                ))}
            </Breadcrumb>
        </>

    
    );
}

export default BlogBreadcrumb;
