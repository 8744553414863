import React,{ useState, useEffect} from "react";
// import { Row, Col} from "antd";
// import { Link } from "react-router-dom";
// import { urls } from "../../router/auth";
import {useSelector} from 'react-redux';
import IntroductoryText from "../Home/IntroductoryText";
import CategoryBox from "../../components/CategoryBox";
import { LocalStorageService } from "../../helpers/LocalStorage";
import { getCategoriesDetailsApi} from '../../services/contents';

const HomePage = () => {
const ilkullanici = true;
const [categoriesDetails, setCategoriesDetails] = useState<any>([])
// const {userIp} = useSelector((state: any) => state.user);

const getCategoriesDetails = async () => {
  try{
    const response:any = await getCategoriesDetailsApi();
    console.log('kategori detay', response)
    if(response){
      setCategoriesDetails(response?.categories)
    }
  }catch(e:any) {
    console.log('e',e)
  }
}

useEffect(() => {
  getCategoriesDetails();
},[])

  return (
    <>
    { ilkullanici ? '' : <IntroductoryText/> }
    <CategoryBox categoriesDetails={categoriesDetails}/>
    </>
  );
};

export default HomePage;
