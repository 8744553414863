import React, { useEffect } from "react";
import {useLocation,  useNavigate } from "react-router-dom";
import {confirmApi} from '../services/userAuth'

const Confirm = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const confirmUrl = location.pathname.split('/');
    const token = confirmUrl[3];

    const ConfirmFunc = async () => {
        try{
            await confirmApi(token);
            navigate("/giris");
        }catch(e:any){
            console.log('hata', e.status)
        }
    }
    
useEffect(() => {
    ConfirmFunc()
},[])

    return (
        <>
        </>
    )
}

export default Confirm; 