/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = true;
      setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
      fakeAuthProvider.isAuthenticated = false;
      setTimeout(callback, 100);
  },
};
  
const urls = {
  homePage: '/',
  login: '/giris',
  signup: '/kayit-ol',
  topicList:'/gundem/:categorieId/:categorieName',
  blog: '/blog',
  deneme:'/:link'
};


export const breadcrumbItems = [
  { name: 'Anasayfa', url: '/' },
  { name: 'Gündem', url: '/gundem/:categorieId/:categorieName' },
];

export  { fakeAuthProvider, urls };
  