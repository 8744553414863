/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

interface TopicState {
  topCategories: any;
}

const initialState = {
  topCategories: [],
} as TopicState;

const topicSlice = createSlice({ 
  name: 'topicForm',
  initialState,
  reducers: {
    setTopCategories(state, action) {
      state.topCategories = action.payload;
    },
  },
});

export const { setTopCategories } = topicSlice.actions;
export default topicSlice.reducer;
