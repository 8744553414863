import React, { useState } from "react";
import {  Form, Input, Button, DatePicker, Select, Radio, Checkbox ,Col, Row, notification } from 'antd';
import moment from 'moment';
import 'dayjs/locale/tr'
import '../../node_modules/moment/locale/tr';
import locale from '../../node_modules/antd/es/date-picker/locale/tr_TR';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {useSelector} from 'react-redux';
import {validatePassword} from '../helpers/validators';
import {  createUserApi } from "../services/userAuth";
import { LocalStorageService } from "../helpers/LocalStorage";
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
// import { LocalStorageService } from "../helpers/LocalStorage";

// const { Option } = Select;

const SignUp = () => {
    // let navigate = useNavigate();
    // const dispatch = useDispatch()
    const [form] = Form.useForm();
    const {userIp} = useSelector((state: any) => state.user);
    const [isLoading, setIsLoading] = useState(false);

    const disabledDate = (current:any) => {
      // current tarihini bugünün tarihinden sonra olup olmadığını kontrol et
      return current && current > moment().endOf('day');
    };


    const onFinish = async (values:any) => {
        try{
          if(values.password !== values.passwordConfirmation){
            notification.warning({
              message: `Şifre ve şifre tekrarın birbiriyle aynı değil.`,
            });
          }else{
            setIsLoading(true)
            const response:any = await createUserApi({
              email:values.email,
              userName:values.username,
              password:values.password,
              passwordConfirmation:values.passwordConfirmation,
              dateOfBirth:moment(values.dateOfBirth).format('YYYY-MM-DD'),
              gender:values.gender,
            });
            if(response){
              setIsLoading(false);
              notification.success({
                message: `Üyeliğiniz başarıyla oluşturuldu. Üyeliğinizi aktif duruma getirmek için lütfen mail adresinizi kontrol ediniz.`,
                duration: 12
              });
              form.resetFields();
            }
          }
        }catch(e:any){
          setIsLoading(false)
          console.log('hata',e)
          if(e.data.status === 409 && e.data.exceptionType.includes('UsernameAlreadyExistsException')){
            notification.error({message: 'Bu kullanıcı adı zaten kayıtlı. Lütfen başka bir kullanıcı adı giriniz.'});
          }
          if(e.data.status === 409 && e.data.exceptionType.includes('EmailAlreadyExistsException')){
            notification.error({message: 'Bu email zaten kayıtlı. Lütfen başka bir email giriniz.'});
          }
          if(e.data.status === 400 && e.data.exceptionType.includes('PasswordsNotMatchedException')){
            notification.error({message: 'Şifre ve şifre tekranınız eşleşmiyor.'});
          }
          
        }
        
      };

    return (
        <>
        <Row style={{justifyContent:'center'}}>
          <Col span={18}>
            <div className='blog-card' >
              <div className='cardx' style={{padding:'40px'}}>
                <article className='article'>
                  <div className='right-txtt'>
                    <h2 style={{textAlign:'center'}}>ÜCRETSİZ Kayıt Ol</h2>
                    <div className="signup-form">
                      <Form
                        form={form}
                        name="registration"
                        layout="vertical"
                        onFinish={onFinish}
                      >
                        <Form.Item
                          label="Kullanıcı Adı / Nickname"
                          name="username"
                          rules={[
                            {
                                required: true,
                                message: 'Lütfen kullanıcı adı giriniz.',
                            },
                        ]}
                        >
                          <Input /> 
                        </Form.Item>

                        <Form.Item
                          label="E-posta"
                          name="email"
                          rules={[
                            { required: true, message: 'E-posta zorunludur!', type: 'email' }
                          ]}
                        >
                          <Input />
                        </Form.Item>

                        <Form.Item
                          label="Şifre"
                          name="password"
                          rules={[
                            {
                              required: true,
                              validator: (_, value) => validatePassword(value)
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <Form.Item
                          label="Şifre Tekrar"
                          name="passwordConfirmation"
                          rules={[
                            {
                              required: true,
                              validator: (_, value) => validatePassword(value)
                            },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>

                        <Form.Item
                          label="Doğum Tarihi"
                          name="dateOfBirth"
                          rules={[{ required: true, message: 'Doğum tarihi zorunludur!' }]}
                        >
                          <DatePicker picker="date" locale={locale} format='DD/MM/YYYY' placeholder='Tarih Seçiniz' disabledDate={disabledDate}  />
                        </Form.Item>

                        <Form.Item
                          label="Cinsiyet"
                          name="gender"
                          rules={[{ required: true, message: 'Cinsiyet zorunludur!' }]}
                        >
                          <Radio.Group>
                            <Radio value="female">Kadın</Radio>
                            <Radio value="male">Erkek</Radio>
                          </Radio.Group>
                        </Form.Item>

                        {/* <Form.Item
                          label="İlişki Durumunuz"
                          name="relationshipStatus"
                          rules={[{ required: true, message: 'İlişki durumu zorunludur!' }]}
                        >
                          <Select placeholder="İlişki durumunuzu seçin">
                            <Option value="evli">Evliyim</Option>
                            <Option value="bekar">Bekarım</Option>
                            <Option value="sozlu">Sözlüyüm</Option>
                            <Option value="nisanli">Nişanlıyım</Option>
                            <Option value="iliskimvar">İlişkim Var</Option>
                            <Option value="karmaşik">Karmaşık Bir İlişkim Var</Option>
                            <Option value="dul">Dulum</Option>
                            <Option value="bosanmis">Boşandım</Option>
                          </Select>
                        </Form.Item> */}

                        {/* <Form.Item
                          label="Meslek"
                          name="profession"
                          rules={[{ required: true, message: 'Meslek zorunludur!' }]}
                        >
                          <Input />
                        </Form.Item> */}

                        {/* <Form.Item
                          label="Eğitim Durumunuz"
                          name="education"
                          rules={[{ required: true, message: 'Eğitim durumu zorunludur!' }]}
                        >
                          <Input />
                        </Form.Item> */}

                        {/* <Form.Item
                          label="Yaşadığınız Yer"
                          name="location"
                          rules={[{ required: true, message: 'Yaşadığınız yer zorunludur!' }]}
                        >
                          <Input />
                        </Form.Item> */}

                        {/* <Form.Item
                          label="Çocuğunuz var mı?"
                          name="hasChildren"
                          rules={[{ required: true, message: 'Bu alan zorunludur!' }]}
                        >
                          <Radio.Group>
                            <Radio value="yok">Yok</Radio>
                            <Radio value="var">Var</Radio>
                            <Radio value="varKulup">Var ve Anneler Kulübüne üye olmak istiyorum</Radio>
                          </Radio.Group>
                        </Form.Item> */}

                        {/* <Form.Item
                          label="Güvenlik Sorusu: Annenizin kız kardeşi neyiniz olur?"
                          name="securityQuestion"
                          rules={[{ required: true, message: 'Bu soru zorunludur!' }]}
                        >
                          <Input />
                        </Form.Item> */}

                        {/* <Form.Item
                          name="agreement"
                          valuePropName="checked"
                          rules={[{ required: true, message: 'Kullanım koşullarını kabul etmelisiniz!' }]}
                          style={{textAlign:'right', marginTop:'20px'}}
                        >
                          <Checkbox style={{marginRight:'10px'}}/>
                           <a>Kullanım Koşulları</a> ,<a> Site Kuralları</a> ve <a>Gizlilik Politikası</a>  Kabul Ediyorum
                          
                        </Form.Item> */}

                        <Form.Item style={{textAlign:'center', marginTop:'20px'}}>
                          <Button type="primary" htmlType="submit" loading={isLoading}>
                            Kayıt Ol
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </Col>
        </Row>
        </>
    )
}

export default SignUp; 