import {
    combineReducers,
    configureStore,
    createImmutableStateInvariantMiddleware,
  } from '@reduxjs/toolkit';
  import userReducer from './reducers/userSlice';
  import tabReducer from './reducers/tabSlice';
  import topicReducer from './reducers/topicForm';
  
  const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
    ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
  });
  
  const rootReducer = combineReducers({
    user: userReducer,
    tab : tabReducer,
    topicForm : topicReducer
  });
  
  const store: any = configureStore({
    reducer: rootReducer,
    // Note that this will replace all default middleware
    middleware: [immutableInvariantMiddleware],
  });
  
  export type RootState = ReturnType<typeof store.getState>
  
  export default store;
  