import React,{useEffect,useState} from 'react';
import './App.css';
import AppRouter from './router/appRouter';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from "react-redux";

import tr_TR from 'antd/es/locale/tr_TR';
import { ConfigProvider } from 'antd';
import Footer from "./components/Footer";
import Header from "./components/Header";
import {setUserIp} from "./reducers/userSlice";
import {  getIpApi, UserVisitor } from "./services/userAuth";
import { LocalStorageService } from './helpers/LocalStorage';
import {getTopCategoriesApi} from './services/contents';
import {setTopCategories} from "./reducers/topicForm";
import Submenu from "./components/Submenu";

function App() {
 const roles = LocalStorageService.getRole() === 'Member';
 const dispatch = useDispatch();
 const visitorId = LocalStorageService.getVisitorId();
 const [browserInfo, setBrowserInfo] = useState<any>({
  browserName: '',
  browserVersion: '',
  operatingSystem: ''
});

const getBrowserInfo = async () => {
  const userAgent = navigator?.userAgent;
  let browserName = '';
  let browserVersion = '';
  let operatingSystem = '';

  if (userAgent) {
    if (userAgent.indexOf('Firefox') > -1) {
      browserName = 'Firefox';
      browserVersion = userAgent.match(/Firefox\/([\d.]+)/)?.[1] || 'Unknown';
    } else if (userAgent.indexOf('Chrome') > -1) {
      browserName = 'Chrome';
      browserVersion = userAgent.match(/Chrome\/([\d.]+)/)?.[1] || 'Unknown';
    } else if (userAgent.indexOf('Safari') > -1) {
      browserName = 'Safari';
      browserVersion = userAgent.match(/Version\/([\d.]+)/)?.[1] || 'Unknown';
    } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident/') > -1) {
      browserName = 'Internet Explorer';
      browserVersion = userAgent.match(/(MSIE [\d.]+|rv:[\d.]+)/)?.[1] || 'Unknown';
    }

    if (userAgent.indexOf('Windows NT') > -1) {
      operatingSystem = 'Windows';
    } else if (userAgent.indexOf('Mac OS X') > -1) {
      operatingSystem = 'MacOS';
    } else if (userAgent.indexOf('Linux') > -1) {
      operatingSystem = 'Linux';
    } else if (userAgent.indexOf('Android') > -1) {
      operatingSystem = 'Android';
    } else if (userAgent.indexOf('like Mac') > -1) {
      operatingSystem = 'iOS';
    }
  }

  setBrowserInfo({
    browserName,
    browserVersion,
    operatingSystem
  });
  return browserInfo
};

const getData = async (newData:any) => {
  try{
    const response: any = await UserVisitor(newData);
    LocalStorageService.setTokenVisitor(response?.token);
    LocalStorageService.setVisitorId(response?.visitorId);
    LocalStorageService.setVisitorRole(response?.role);
   }catch(e:any){
    console.log('e',e)
   }
}

const getIp = async () => {
  try{
    const response: any = await getIpApi();
    if(response){
      LocalStorageService.setBrowserCity(encodeURIComponent(response.city));
      LocalStorageService.setBrowserCountry(response.country);
      LocalStorageService.setBrowserIp(response.ip)
      LocalStorageService.setBrowserVersion(browserInfo.browserVersion);
      // dispatch(setUserIp(newData));
      if(visitorId === null){
        getData({
          browserName: browserInfo.browserName,
          browserVersion: browserInfo.browserVersion,
          operationSystem: browserInfo.operatingSystem,
          ipAddress: response.ip,
          country: response.country,
          city: response.city
        })
      }
    }
    
   }catch(e:any){
    console.log('e',e)
   }
}

const getTopCategories = async () => {
  try{
    const response:any = await getTopCategoriesApi()
    if(response){
      dispatch(setTopCategories(response.data));
    }
  }catch(e:any){
    console.log('e',e)
  }
}

 useEffect(() => {
  getBrowserInfo();
  if(browserInfo.browserName !== ''){
    getIp();
  }
 }, [browserInfo.browserName]);

 useEffect(() => {
  getTopCategories();
 },[])

  return (
    <>
      <ConfigProvider locale={tr_TR}>
        <BrowserRouter>
        <Header/>
        <Submenu/>
          <main style={{ paddingTop:'20px', paddingBottom:"20px"}}>
            {/* {
              roles ? (
                <div className="page-left" style={{textAlign:'center'}}>
                  <img src="https://s0.2mdn.net/simgad/17073201058330872157" alt="Advertisement" width="250" height="600"></img>
                </div>
              ):''
            } */}
            
            <div className="page" style={{ margin:'0 auto'}}>
            <AppRouter /> 
            </div>
            {/* {
              roles ? (
                <div className="page-right" style={{textAlign:'center'}}>
                  <img src="https://s0.2mdn.net/simgad/17073201058330872157" alt="Advertisement" width="300" height="600"></img>
                </div>
              ):''
            } */}
            
          </main>
            <Footer />
        </BrowserRouter>
      </ConfigProvider>
    </>
  );
}

export default App;
