export class LocalStorageService {
    static authTokenKey: string = 'auth';
    static visitorToken: string = 'visitorToken';
    static visitorId: string = 'visitorId';
    static visitorRole: string = 'visitoRole';
    static role: string = 'role';
    static browserVersion: string = 'browserVersion';
    static browserCity: string = 'browserCity'; 
    static browserCountry: string = 'browserCountry'; 
    static browserIp: string = 'browserIp';
    static userName: string = 'user-name';

    // token

    static getAuthToken() {
        return sessionStorage.getItem(this.authTokenKey) || localStorage.getItem(this.authTokenKey);
    }

    static setAuthToken(token?: string | any) {
        localStorage.setItem(this.authTokenKey, token);
        sessionStorage.setItem(this.authTokenKey, token);
    }

    static removeAuthToken() {
        localStorage.removeItem(this.authTokenKey);
        sessionStorage.removeItem(this.authTokenKey);
    }

    // user name

    static getUserName() {
        return localStorage.getItem(this.userName);
    }

    static setUserName(userName?: string | any) {
        localStorage.setItem(this.userName, userName);
    }

    static removeUserName() {
        localStorage.removeItem(this.userName);
    }

    // --

    static getTokenVisitor() {
        return sessionStorage.getItem(this.visitorToken) || localStorage.getItem(this.visitorToken);
    }

    static setTokenVisitor(visitorToken?: string | any) {
        localStorage.setItem(this.visitorToken, visitorToken);
        sessionStorage.setItem(this.visitorToken, visitorToken);
    }

    static removeTokenVisitor() {
        localStorage.removeItem(this.visitorToken);
        sessionStorage.removeItem(this.visitorToken);
    }

    // --

    static getBrowserVersion() {
        return sessionStorage.getItem(this.browserVersion) || localStorage.getItem(this.browserVersion);
    }

    static setBrowserVersion(data?: string | any) {
        localStorage.setItem(this.browserVersion, data);
        sessionStorage.setItem(this.browserVersion, data);
    }

    static removeBrowserVersion() {
        localStorage.removeItem(this.browserVersion);
        sessionStorage.removeItem(this.browserVersion);
    }

    // --

    static getBrowserIp() {
        return sessionStorage.getItem(this.browserIp) || localStorage.getItem(this.browserIp);
    }

    static setBrowserIp(data?: string | any) {
        localStorage.setItem(this.browserIp, data);
        sessionStorage.setItem(this.browserIp, data);
    }

    static removeBrowserIp() {
        localStorage.removeItem(this.browserIp);
        sessionStorage.removeItem(this.browserIp);
    }

    // --

    static getBrowserCountry() {
        return sessionStorage.getItem(this.browserCountry) || localStorage.getItem(this.browserCountry);
    }

    static setBrowserCountry(data?: string | any) {
        localStorage.setItem(this.browserCountry, data);
        sessionStorage.setItem(this.browserCountry, data);
    }

    static removeBrowserCountry() {
        localStorage.removeItem(this.browserCountry);
        sessionStorage.removeItem(this.browserCountry);
    }

    // --

    static getBrowserCity() {
        return sessionStorage.getItem(this.browserCity) || localStorage.getItem(this.browserCity);
    }

    static setBrowserCity(data?: string | any) {
        localStorage.setItem(this.browserCity, data);
        sessionStorage.setItem(this.browserCity, data);
    }

    static removeBrowserCity() {
        localStorage.removeItem(this.browserCity);
        sessionStorage.removeItem(this.browserCity);
    }

    // --

    static getVisitorId() {
        return sessionStorage.getItem(this.visitorId) || localStorage.getItem(this.visitorId);
    }

    static setVisitorId(visitorId?: string | any) {
        localStorage.setItem(this.visitorId, visitorId);
        sessionStorage.setItem(this.visitorId, visitorId);
    }

    static removeVisitorId() {
        localStorage.removeItem(this.visitorId);
        sessionStorage.removeItem(this.visitorId);
    }

    // --
    static getRole() {
        return sessionStorage.getItem(this.role) || localStorage.getItem(this.role);
    }

    static setRole(role?: string | any) {
        localStorage.setItem(this.role, role);
        sessionStorage.setItem(this.role, role);
    }

    static removeRole() {
        localStorage.removeItem(this.role);
        sessionStorage.removeItem(this.role);
    }

    // --

    static getVisitorRole() {
        return sessionStorage.getItem(this.visitorRole) || localStorage.getItem(this.visitorRole);
    }

    static setVisitorRole(vrole?: string | any) {
        localStorage.setItem(this.visitorRole, vrole);
        sessionStorage.setItem(this.visitorRole, vrole);
    }

    static removeVisitorRole() {
        localStorage.removeItem(this.visitorRole);
        sessionStorage.removeItem(this.visitorRole);
    }


}
