import HttpService from './HttpService';

const ipApi = process.env.REACT_IP_API_ADDRESS;
const baseUrlApi = process.env.REACT_APP_API_ADDRESS;


export const endpoints = {
    login: '/users/authenticate',
    freeSelf:'free/self'
    
};


export const apiIp = new HttpService({ baseURL: `${ipApi}` });
export const api = new HttpService({ baseURL: `${baseUrlApi}` });



