import React from 'react';
import {Avatar } from "antd";
import { useNavigate } from "react-router-dom";

function CategoryBox(categoriesDetails:any) {
    let navigate = useNavigate();

    const CategorieDetails = (categoryId:any, name:any) => {
        console.log('buaraya geldin',categoryId, name)
        const encodedName = encodeURIComponent(name)
            .replace(/%2C/g, '-')  // Virgülü '-' ile değiştir
            .replace(/%26/g, '-')  // '&' karakterini '-' ile değiştir
            .replace(/%20/g, '-') // Boşlukları '-' ile değiştir
            .replace(/%3F/g, '-'); // '?' karakterini '-' ile değiştir

        navigate(`/${categoryId}/${encodedName}`)
    }

    const formatTimeDifference = (nowDate: any) => {
        const now = new Date();
        const targetDate = new Date(nowDate);
    
       // Türkiye saat diliminde tarih ve saat hesaplamaları
       const optionsDate: Intl.DateTimeFormatOptions = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' };
       const optionsTime: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
    
       // Geçerli bir tarih formatı oluşturuluyor
       const targetDateLocal = new Date(targetDate.toLocaleString('en-US', { timeZone: 'Europe/Istanbul' }));
       const nowLocal = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Istanbul' }));
    
       // Tarih ve saat farkı hesaplama
       const diffMilliseconds = nowLocal.getTime() - targetDateLocal.getTime();
       const diffMinutes = Math.floor(diffMilliseconds / (1000 * 60));
       const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60));
       const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
    
       const targetDay = targetDateLocal.toLocaleDateString('tr-TR', optionsDate);
       const targetTime = targetDateLocal.toLocaleTimeString('tr-TR', optionsTime);
    
       if (diffMinutes < 60) {
         return `${diffMinutes} dakika önce`;
       } else if (targetDateLocal.getDate() === nowLocal.getDate()) {
         return `Bugün ${targetTime}`;
       } else if (targetDateLocal.getDate() + 1  === nowLocal.getDate() ) {
         return `Dün ${targetTime}`;
       } else if (diffDays < 31) {
         return `${diffDays} gün önce`;
       } else {
         return targetDay;
       }
      };

    
    return (
        <>
        {
            categoriesDetails.categoriesDetails.map((item:any) => {
                return(
                    <div className='blog-card'>
                        <div className='cardx'>
                            <article className='article'>

                                <div className='right-txtt'>
                                    <div className='box-icon'>
                                        <div className='icon-icon'>
                                            <img alt='' src={`home/${item.iconPath}`} width='20px'></img>
                                        </div>
                                        <div onClick={() => CategorieDetails(item.categoryId, item.name)} className='card-bloc-ttle handle' style={{ display: 'flex' }}>
                                            <a className='card-title'>{item.name}</a>
                                            <span>{item.description}</span>
                                        </div>
                                    </div>
                                    <div className='right-card'>

                                        {
                                            item.subcategories.map((i: any) => {
                                                return (
                                                    <div className='box-card'>
                                                        <div className='box-card-div'>
                                                            <article className='box-card-article'>
                                                                <div className='box-card-link'>
                                                                    <div>
                                                                        <a href={i.seoLink}>{i.name}</a>
                                                                    </div>
                                                                    <div className='box-card-staticyclic'>
                                                                        <span>
                                                                            <pre>Açılan Konu Sayısı</pre>
                                                                            {i.topicsCount}
                                                                        </span>
                                                                        <span>
                                                                            <pre>Gelen Mesaj Sayısı</pre>
                                                                            {i.messagesCount}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='box-card-info'>
                                                                    <div><Avatar size={40} icon={'NY'} /></div>
                                                                    <div className='information-div'>
                                                                        <span style={{ color: '#000' }} className="box-title">
                                                                            <a href={i.lastMessageTopicLink}>{i.lastMessageTopic}</a>
                                                                        </span>
                                                                        <span style={{ color: '#000' }} className='box-time'>{formatTimeDifference(i.lastMessageDate)}</span>
                                                                        <span style={{ color: '#000' }} className='box-user'>{i.lastMessageAuthor}</span>
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            </article>
                        </div>

                    </div>
                )
            })
        }
       
        </>
        
    );
}

export default CategoryBox;
